















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mdiRefresh, mdiFactory, mdiPencil, mdiKeyRemove } from '@mdi/js';
import type { JsonObject } from 'type-fest';
import {
  getUser,
  invalidateAllTokens,
  setUserGroup,
  setEnabledStatus
} from '../../api/admin';
import _ from 'lodash';
import { Hub } from '@aws-amplify/core';
import { ADMIN_USER_GROUP, SUPPORT_USER_GROUP } from '../../constants';
import Auth from '@aws-amplify/auth';

interface TableRow {
  path: string;
  value: unknown;
}

@Component
export default class UserTableView extends Vue {
  private readonly ADMIN_USER_GROUP: typeof ADMIN_USER_GROUP =
    ADMIN_USER_GROUP;
  private readonly SUPPORT_USER_GROUP: typeof SUPPORT_USER_GROUP =
    SUPPORT_USER_GROUP;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiFactory: string = mdiFactory;
  private readonly mdiPencil: string = mdiPencil;
  private readonly mdiKeyRemove: string = mdiKeyRemove;
  private readonly userGroupItems: Array<{
    text: string;
    value: string;
  }> = [
    {
      text: '$vuetify.user.REGULAR_USER',
      value: ''
    },
    {
      text: '$vuetify.user.ADMIN',
      value: ADMIN_USER_GROUP
    },
    {
      text: '$vuetify.user.SUPPORT',
      value: SUPPORT_USER_GROUP
    }
  ];

  private loading: boolean = true;
  private dialogShow: boolean = false;
  private tableItems: Array<TableRow> = [];
  private userGroups: string[] = [];

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.user.PROPERTY'),
        value: 'path',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.user.VALUE'),
        value: 'value',
        sortable: false
      }
    ];
  }

  @Prop({
    type: String,
    required: true,
    validator: (value: string): boolean => !!value
  })
  public sub!: string;

  private async created(): Promise<void> {
    this.userGroups =
      (
        await Auth.currentSession().catch((): undefined => undefined)
      )?.getIdToken()?.payload?.['cognito:groups'] || [];
  }

  private async mounted(): Promise<void> {
    this.getUser();
  }

  private getLeaves(tree: JsonObject, rootPath: string = ''): string[] {
    const leaves: string[] = [];
    function walk(branch: JsonObject, basePath: string = ''): void {
      for (var n in branch) {
        if (Object.prototype.hasOwnProperty.call(branch, n)) {
          const newPath: string = basePath ? `${basePath}.${n}` : n;
          if (typeof branch[n] === 'object' || branch[n] instanceof Array) {
            walk(branch[n] as JsonObject, newPath);
          } else {
            leaves.push(newPath);
          }
        }
      }
    }
    walk(tree, rootPath);
    return leaves;
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getUser(): void {
    this.loading = true;
    getUser(this.sub)
      .then((value: JsonObject): void => {
        this.tableItems = this.getLeaves(value || {}).map(
          (path: string): TableRow => {
            return {
              path,
              value: _.get(value, path, '')
            };
          }
        );
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private updateDeviceProperty(path: string, value: unknown): void {
    this.loading = true;
    let prom: Promise<void>;
    switch (path) {
      case 'UserGroup':
        prom = setUserGroup(this.sub, value as string);
        break;
      case 'Enabled':
        prom = setEnabledStatus(this.sub, value as boolean);
        break;
      default:
        this.loading = false;
        return;
    }
    prom
      .then((): void => {
        this.getUser();
        this.$emit('refreshAdminState');
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }

  private invalidateAllTokens(): void {
    if (!this.sub) {
      return;
    }
    this.loading = true;
    this.dialogShow = false;
    invalidateAllTokens(this.sub)
      .then((): void => this.getUser())
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }
}
