var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('v-subheader',{staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.COGNITO_USER'))+" "),_c('v-spacer'),(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiKeyRemove))])],1)]}}],null,false,1130250950)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.GLOBAL_SIGNOUT'))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/production?search=" + _vm.sub)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiFactory))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.OPEN_PRODUCTION'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getUser}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.REFRESH'))+" ")])])],1),_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp
        ? 'height: calc(100vh - 64px - 48px); position: relative;'
        : 'height: 100%; position: relative')},[_c('v-data-table',{style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height: 100%'),attrs:{"loading":_vm.loading,"headers":_vm.tableHeaders,"items":_vm.tableItems,"sort-by":"path","must-sort":"","disable-pagination":"","fixed-header":"","height":_vm.$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 64px - 48px)' : '',"hide-default-footer":"","items-per-page":-1,"footer-props":{
        disablePagination: true,
        disableItemsPerPage: true
      }},scopedSlots:_vm._u([(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?{key:"item.value",fn:function(ref){
      var item = ref.item;
return [(['Enabled', 'UserGroup'].includes(item.path))?_c('v-edit-dialog',{attrs:{"large":"","cancel-text":_vm.$vuetify.lang.t('$vuetify.device.CANCEL'),"save-text":_vm.$vuetify.lang.t('$vuetify.device.SAVE')},on:{"save":function($event){return _vm.updateDeviceProperty(item.path, item.tmp)},"open":function($event){item.tmp = item.value}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(item.path === 'UserGroup')?_c('v-select',{attrs:{"items":_vm.userGroupItems,"item-text":function (item) { return _vm.$vuetify.lang.t(item.text); },"label":item.path,"single-line":""},model:{value:(item.tmp),callback:function ($$v) {_vm.$set(item, "tmp", $$v)},expression:"item.tmp"}}):_vm._e(),(item.path === 'Enabled')?_c('v-switch',{attrs:{"label":item.path,"single-line":""},model:{value:(item.tmp),callback:function ($$v) {_vm.$set(item, "tmp", $$v)},expression:"item.tmp"}}):_vm._e()]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"flex-nowrap",staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center"}},'v-row',attrs,false),on),[_vm._v(" "+_vm._s(item.value)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.EDIT'))+" ")])])],1):[_vm._v(" "+_vm._s(item.value)+" ")]]}}:null],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.GLOBAL_SIGNOUT'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.sub)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.CONFIRM_GLOBAL_SIGNOUT'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialogShow = false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.invalidateAllTokens}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.user.SIGNOUT'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }